<template>
  <div id="landingpage">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box w="100%" maxWidth="1200px" mx="auto" :px="['20px', '0']">
        <c-breadcrumb
          marginTop="20px"
          marginBottom="20px"
          v-chakra="{
            ol: {
              li: {
                a: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
                span: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
              },
            },
          }"
        >
          <c-breadcrumb-item>
            <c-breadcrumb-link
              href="#"
              color="brand.900"
              fontSize="14px"
              fontFammily="Roboto"
              >Beranda
            </c-breadcrumb-link>
          </c-breadcrumb-item>

          <c-breadcrumb-item isCurrentPage>
            <c-breadcrumb-link
              href="#"
              fontSize="14px !important"
              fontFammily="Roboto !important"
            >
              Kuisioner
            </c-breadcrumb-link>
          </c-breadcrumb-item>
        </c-breadcrumb>
        <c-box borderTop="1px solid #F2F2F2" paddingTop="40px">
          <c-box textAlign="center">
            <c-text
              fontSize="16px"
              fontWeight="700"
              fontFamily="Roboto"
              textTransform="uppercase"
              color="brand.900"
              marginBottom="10px"
            >
              Kuisioner
            </c-text>
            <c-heading
              as="h2"
              fontSize="36px"
              fontWeight="600"
              color="black.900"
              text-align="center"
            >
              Informed Consent
            </c-heading>
          </c-box>
          <c-box w="100%" maxWidth="794px" mx="auto" marginTop="30px">
            <c-box
              w="100%"
              h="635px"
              overflowY="auto"
              px="35px"
              py="35px"
              backgroundColor="superLightGray.900"
            >
              <c-text
                fontSize="16px"
                color="black.900"
                marginBottom="10px"
                v-html="consent"
              />
            </c-box>
            <c-checkbox
              alignItems="flex-start"
              size="lg"
              variant-color="green"
              fontSize="18px"
              lineHeight="27px"
              color="#000000"
              marginTop="20px"
              v-model="agree"
            >
              Saya menyetujui semua pernyataan diatas
            </c-checkbox>
            <c-checkbox
              alignItems="flex-start"
              size="lg"
              variant-color="green"
              fontSize="18px"
              lineHeight="27px"
              color="#000000"
              marginTop="20px"
            >
              Saya menyetujui data saya boleh digunakan untuk keperluan
              pengembangan dan penelitian ilmu gizi dan kesehatan dengan
              identitas saya dirahasiakan
            </c-checkbox>
            <c-box marginTop="30px" textAlign="right">
              <c-button
                right-icon="arrow-forward"
                backgroundColor="brand.900"
                color="white"
                borderRadius="100px"
                fontSize="16px"
                px="20px !important"
                size="lg"
                :disabled="!agree"
                :is-disabled="!agree"
                as="router-link"
                :to="agree ? { name: 'client.kuisioner1', params: $route.params } : ''"
              >
                Selanjutnya
              </c-button>
            </c-box>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import Consent from "./InformedConsent.html";

export default {
  name: "statement",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      agree: false,
      consent: Consent,
    };
  },
};
</script>

<style></style>